class SliderAthleteWidgetHandler extends elementorModules.frontend.handlers
    .Base {
    updateSlider() {
        const currentEle = this.$element[0]
        const sliderEle = currentEle.querySelector(
            '.slider-home-athlete__slider'
        )

        if (currentEle && sliderEle) {
            new Swiper(sliderEle, {
                slidesPerView: 'auto',
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    1200: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                    },
                },
            })
        }
    }

    onInit() {
        this.updateSlider()
    }
}

window.addEventListener('elementor/frontend/init', () => {
    elementorFrontend.elementsHandler.attachHandler(
        'hiqua_home_slider_athlete',
        SliderAthleteWidgetHandler
    )
})
